<template>
  <div class="page-wrapper">
    <div class="main_container">
      <div id="analyticsPage">
        <div class="top-header-tools">
          <div class="trace-tab-buttons-container">
            <div
              :class="`trace-tab-button ${
                activeTab === 'analytics' ? 'trace-tab-button-active' : ''
              }`"
              @click="onClickTab('analytics')"
            >
              Analytics
            </div>
            <div
              :class="`trace-tab-button ${
                activeTab === 'subscriptions' ? 'trace-tab-button-active' : ''
              }`"
              @click="onClickTab('subscriptions')"
            >
              Subscriptions
            </div>
          </div>

          <div class="filter-row">
            <v-select
              v-if="activeTab === 'analytics'"
              v-model="selectedDateRange"
              :items="dateRangeAnalytics"
              :label="dateRangeLabel"
              class="filter-select"
              outlined
              dense
              hide-details
              @mousedown.stop.prevent="openDatePicker"
              @click:append="openDatePicker"
              @change="handleDateRangeChange"
              readonly
            ></v-select>
            <v-select
              v-if="activeTab === 'subscriptions'"
              v-model="selectedDateRange"
              :items="dateRangeSubscriptions"
              :label="dateRangeLabel"
              class="filter-select"
              outlined
              dense
              hide-details
              @mousedown.stop.prevent="openDatePicker"
              @click:append="openDatePicker"
              @change="handleDateRangeChange"
              readonly
            ></v-select>
            <v-select
              v-model="accountTypes[activeTab]"
              :items="accountTypeOptions"
              item-text="text"
              item-value="value"
              label="Account type"
              class="filter-type"
              outlined
              dense
              hide-details
            ></v-select>
          </div>
        </div>

        <div v-if="activeTab === 'analytics'">
          <div style="margin-left: 80px !important; margin-top: 30px">
            <analytics-graph
              contentId="analytics-graph-content"
              :data="graphData"
              :loading="graphLoading"
              @checked="updateCheckedGraphTypes"
              @update="getGraphData"
            />
          </div>
          <div class="filter-row">
            <div class="tiles-container">
              <div
                v-for="tile in analyticsTilesArray"
                :key="tile.title"
                class="tile-item"
              >
                <analytics-tile
                  :title="tile.title"
                  :count="tile.count"
                  :percentage="tile.percentage"
                  :loading="
                    tile.type === 'company'
                      ? companyTilesLoading
                      : productTilesLoading
                  "
                />
              </div>
            </div>
          </div>

          <div style="margin: 40px 0px 0px 80px">
            <div class="listings-container">
              <analytics-listing
                v-for="listing in listingArray"
                :key="listing.title"
                :title="listing.title"
                :loading="listing.loading"
                :items="listing.data"
                :totalCount="listing.total"
                :currentPage="listing.page"
                :perPageCount="4"
                @load-page="loadListingPage(listing.loadPage, ...arguments)"
              />
            </div>
          </div>
          <div style="margin: 40px 0px 0px 80px">
            <heat-map
              title="Registered by Country"
              countryLegendTitle="Top Countries"
              countLegendTitle="Companies"
              :countriesData="countriesMapPagination.data"
              :loading="mapLoading"
              :totalCount="countriesMapPagination.total"
              :perPageCount="countriesMapPagination.perPage"
              :currentPage="countriesMapPagination.page"
              :maxVisiblePages="5"
              @load-page="changeMapPage"
            />
          </div>
        </div>

        <div v-if="activeTab === 'subscriptions'">
          <div class="filter-row">
            <div class="tiles-container">
              <div
                v-for="tile in subscriptionsTilesArray"
                :key="tile.title"
                class="tile-item"
              >
                <analytics-tile
                  :title="tile.title"
                  :count="tile.count"
                  :percentage="tile.percentage"
                  :variant="tile.variant"
                  :amount="
                    tile.amount || tile.amount === 0
                      ? `GBP ${tile.amount.toLocaleString()}`
                      : null
                  "
                  :loading="subscriptionTilesLoading"
                />
              </div>
            </div>
          </div>

          <div style="margin: 40px 0px 0px 80px">
            <subscription-listing
              :subscriptions="subscriptionListing.data"
              :packages="packages"
              :loading="subscriptionListing.loading"
              :current-page="subscriptionListing.page"
              :total-count="subscriptionListing.total"
              :per-page-count="25"
              :max-visible-pages="5"
              @delete="deleteSubscription"
              @fetch="getSubscriptionList"
              @new="openNewSubscriptionDialog"
            />
          </div>
        </div>

        <v-dialog
          v-model="dateMenus[activeTab].range"
          max-width="450px"
          persistent
          scrollable
          @click:outside="closeDatePicker"
        >
          <v-card class="centered-card">
            <v-card-title>Select timeframe</v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="4">
                  <v-tabs
                    vertical
                    v-model="selectedTab[activeTab]"
                    class="tabs-column"
                  >
                    <v-tab
                      v-for="option in tabOptions"
                      :key="option"
                      @click="selectTab(option)"
                      class="tab-item"
                    >
                      {{ option }}
                    </v-tab>
                  </v-tabs>
                </v-col>

                <v-col cols="1">
                  <v-divider vertical class="divider"></v-divider>
                </v-col>

                <v-col cols="7">
                  <v-text-field
                    v-model="dateRanges[activeTab].from"
                    label="Start"
                    type="date"
                    class="date-input"
                    outlined
                    :disabled="selectedTab[activeTab] !== 0"
                    :max="dateRanges[activeTab].to || null"
                    @change="selectTab()"
                  ></v-text-field>
                  <v-text-field
                    v-model="dateRanges[activeTab].to"
                    label="End"
                    type="date"
                    class="date-input"
                    outlined
                    :disabled="selectedTab[activeTab] !== 0"
                    :min="dateRanges[activeTab].from || null"
                    @change="selectTab()"
                  ></v-text-field>
                  <v-alert
                    v-if="!isDateRangeValid"
                    type="error"
                    border="left"
                    class="mt-2"
                  >
                    The "To" date must be later than the "From" date.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeDatePicker">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <popup-window
          :active="newSubscriptionDialog"
          :hasClose="false"
          :width="'500px'"
          :maxWidth="'500px'"
          noMargin
          @popup-close="closeNewSubscriptionDialog"
          @popup-back="closeNewSubscriptionDialog"
        >
          <div class="new-subscription-container">
            <div class="new-subscription-title">Add a Subscription</div>
            <v-form
              ref="newSubscriptionData"
              class="new-subscription-input-container"
            >
              <v-autocomplete
                clearable
                :search-input.sync="searchAccount"
                v-model="newSubscriptionData.account"
                :items="accountList.data"
                item-value="id"
                label="Company"
                :filter="() => true"
                :hide-no-data="accountList.loading"
                :loading="accountList.loading"
                :rules="newSubscriptionRules.account"
                outlined
                dense
              >
                <template v-slot:item="{ item }">
                  <span style="text-align: left; word-wrap: anywhere">{{
                    item.organization_registry.name
                  }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span style="text-align: left; word-wrap: anywhere">{{
                    item.organization_registry.name
                  }}</span>
                </template>
                <template v-slot:append-item>
                  <infinite-loading
                    ref="InfiniteLoading"
                    @infinite="fetchAccountList"
                  >
                    <div slot="no-more" v-if="accountList.data.length">
                      No more data
                    </div>
                    <div slot="no-more" v-if="!accountList.data.length">
                      No data available
                    </div>
                    <div slot="no-results" v-if="accountList.data.length">
                      No more data
                    </div>
                    <div slot="no-results" v-if="!accountList.data.length">
                      No data available
                    </div>
                  </infinite-loading>
                </template>
              </v-autocomplete>
              <v-select
                v-model="newSubscriptionData.package"
                :items="packages.filter((item) => item.id !== null)"
                item-text="name"
                item-value="id"
                label="Package"
                placeholder="Select package"
                :rules="newSubscriptionRules.package"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="newSubscriptionData.due"
                label="Due date"
                type="date"
                :min="new Date().toISOString().split('T')[0]"
                :rules="newSubscriptionRules.due"
                outlined
                dense
              ></v-text-field>
              <div
                v-if="
                  !packages ||
                  !packages.length ||
                  !(
                    newSubscriptionData.package ===
                    packages.find((item) => item.short_code === 'free').id
                  )
                "
                style="width: 50%"
              >
                <v-text-field
                  v-model="newSubscriptionData.amount"
                  label="Amount"
                  type="number"
                  background-color="#fff"
                  :rules="newSubscriptionRules.amount"
                  :min="0"
                  prepend-inner-icon="mdi-currency-gbp"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </v-form>
            <div class="new-subscription-actions-container">
              <v-switch
                v-if="newSubscriptionData.account"
                v-model="newSubscriptionData.verified"
                :label="
                  newSubscriptionData.verified ? 'Verified' : 'Not verified'
                "
                hide-details
              ></v-switch>
              <div class="new-subscription-actions">
                <ui-button
                  class="primary"
                  title="Add Subscription"
                  @click="createSubscription"
                />
                <ui-button
                  class="danger"
                  title="Cancel"
                  @click="closeNewSubscriptionDialog"
                />
              </div>
            </div>
          </div>
        </popup-window>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsGraph from "@/components/AnalyticsGraph.vue";
import AnalyticsTile from "@/components/AnalyticsTile.vue";
import AnalyticsListing from "@/components/AnalyticsListing.vue";
import restAdapter from "../../../restAdapter";
import HeatMap from "@/components/HeatMap.vue";
import SubscriptionListing from "@/components/SubscriptionListing.vue";
import InfiniteLoading from "vue-infinite-loading";
import debounce from "lodash/debounce";
import moment from "moment";
import * as conf from "../../../conf.yml";

export default {
  name: "Analytics",
  components: {
    AnalyticsGraph,
    AnalyticsTile,
    AnalyticsListing,
    HeatMap,
    SubscriptionListing,
    InfiniteLoading,
  },
  data: () => ({
    baseUrl: conf.default.server.host,
    activeTab: "analytics",
    packages: [],
    accountTypes: {
      analytics: "all",
      subscriptions: "all",
    },
    selectedTab: {
      analytics: 1,
      subscriptions: 1,
    },
    tabOptions: [
      "Custom",
      "1 month",
      "2 weeks",
      "3 months",
      "6 months",
      "1 year",
    ],
    accountTypeOptions: [
      {
        text: "All",
        value: "all",
      },
      {
        text: "Brand",
        value: "brand",
      },
      {
        text: "Supplier",
        value: "supplier",
      },
    ],
    dateRanges: {
      analytics: {
        from: null,
        to: null,
      },
      subscriptions: {
        from: null,
        to: null,
      },
    },
    dateMenus: {
      analytics: {
        range: false,
      },
      subscriptions: {
        range: false,
      },
    },
    selectedDateRange: "default",
    dateRangeAnalytics: [{ text: "Select timeframe", value: "default" }],
    dateRangeSubscriptions: [{ text: "Select timeframe", value: "default" }],
    today: new Date().toISOString().split("T")[0],
    companyTilesLoading: false,
    productTilesLoading: false,
    subscriptionTilesLoading: false,
    accountType: "all",
    mapLoading: false,
    countriesMapPagination: {
      page: 1,
      perPage: 4,
      total: 1,
      data: [],
    },
    companyTiles: {
      createdRegistries: {
        title: "Global List",
        count: 0,
        percentage: null,
        type: "company",
      },
      registeredCompanies: {
        title: "Registered Companies",
        count: 0,
        percentage: null,
        type: "company",
      },
      verifiedCompanies: {
        title: "Verified Companies",
        count: 0,
        percentage: null,
        type: "company",
      },
      unverifiedCompanies: {
        title: "Canceled Company Verifications",
        count: 0,
        percentage: null,
        type: "company",
      },
      paidSubscriptions: {
        title: "Paid Subscriptions",
        count: 0,
        percentage: null,
        type: "company",
      },
    },
    productTiles: {
      createdCollections: {
        title: "Collections Created ",
        count: 0,
        percentage: null,
        type: "product",
      },
      completedCollections: {
        title: "Collections Completed",
        count: 0,
        percentage: null,
        type: "product",
      },
      createdStyles: {
        title: "Styles Created",
        count: 0,
        percentage: null,
        type: "product",
      },
      completedStyles: {
        title: "Styles Completed",
        count: 0,
        percentage: null,
        type: "product",
      },
      totalProducts: {
        title: "Total Products",
        count: 0,
        percentage: null,
        type: "product",
      },
      dppCreated: {
        title: "DPP Created",
        count: 0,
        percentage: null,
        type: "product",
      },
      dppPublished: {
        title: "DPP Published",
        count: 0,
        percentage: null,
        type: "product",
      },
      updatesPublishedDpp: {
        title: "Updated Published DPP",
        count: 0,
        percentage: null,
        type: "product",
      },
      totalTraceableProducts: {
        title: "Total Traceable Products",
        count: 0,
        percentage: null,
        type: "product",
      },
    },
    subscriptionTiles: {
      freeSubscriptions: {
        title: "Free",
        count: 0,
        percentage: null,
        type: "subscription",
      },
      startupSubscriptions: {
        title: "Startup",
        count: 0,
        amount: 0,
        percentage: null,
        type: "subscription",
      },
      growthSubscriptions: {
        title: "Growth",
        count: 0,
        amount: 0,
        percentage: null,
        type: "subscription",
      },
      enterpriseSubscriptions: {
        title: "Enterprise",
        count: 0,
        amount: 0,
        percentage: null,
        type: "subscription",
      },
      totalSubscriptions: {
        title: "Total Revenue",
        count: 0,
        amount: 0,
        percentage: null,
        type: "subscription",
        variant: "primary",
      },
    },
    subscriptionListing: {
      data: [],
      loading: false,
      total: 0,
      page: 1,
      filters: {
        search: "",
        package: null,
      },
    },
    accountList: {
      data: [],
      loading: false,
      page: 1,
      search: "",
    },
    newSubscriptionDialog: false,
    newSubscriptionData: {
      package: null,
      account: null,
      due: null,
      amount: null,
      verified: false,
    },
    newSubscriptionRules: {
      package: [(v) => !!v || "Package is required"],
      account: [(v) => !!v || "Company is required"],
      due: [(v) => !!v || "Due date is required"],
      amount: [
        (v) => !!v || "Amount is required",
        (v) => v >= 0 || "Amount cannot be negative",
      ],
    },
    companyListing: {
      completedCollectionsListing: {
        title: "Completed Collections",
        data: [],
        loading: false,
        total: 0,
        page: 1,
        loadPage: "getCollectionListing",
      },
      publishedTraceListing: {
        title: "Published Digital Product Passports",
        data: [],
        loading: false,
        total: 0,
        page: 1,
        loadPage: "getPublishedTraceListing",
      },
      activeCompaniesListing: {
        title: "Most Active Companies (Messages engagements)",
        data: [],
        loading: false,
        total: 0,
        page: 1,
        loadPage: "getActiveCompaniesListing",
      },
      inactiveCompaniesListing: {
        title: "Inactive Companies (last active)",
        data: [],
        loading: false,
        total: 0,
        page: 1,
        loadPage: "getInactiveCompaniesListing",
      },
      mobileAppUsageListing: {
        title: "Mobile App Usage",
        data: [],
        loading: false,
        total: 0,
        page: 1,
        loadPage: "getMobileAppUsageListing",
      },
    },
    subscriptionFilters: {
      search: "",
      packageId: null,
      order: "asc",
      page: 1,
    },
    retrievedGraphTypes: [],
    graphClassesCount: 0,
    checkedGraphTypes: [],
    graphData: {},
    graphLoading: false,
  }),
  watch: {
    accountTypes: {
      handler() {
        this.debounceAnalytics();
      },
      deep: true,
    },
    dateRanges: {
      handler() {
        this.debounceAnalytics();
      },
      deep: true,
    },
    activeTab() {
      this.debounceAnalytics();
    },
    "newSubscriptionData.account": {
      handler() {
        this.newSubscriptionData.verified = this.accountList.data.find(
          (item) => item.id === this.newSubscriptionData.account
        )?.verified;
      },
    },
  },
  mounted() {
    this.getPackageList();
    this.fetchAccountList();
    this.newSubscriptionData.due = this.getOneYearAheadDate();
    this.setDefaultDateRanges();
    this.debounceAnalytics();
  },
  computed: {
    analyticsTilesArray() {
      return Object.values({ ...this.companyTiles, ...this.productTiles });
    },
    subscriptionsTilesArray() {
      return Object.values(this.subscriptionTiles);
    },
    listingArray() {
      return Object.values(this.companyListing);
    },
    dateRangeLabel() {
      return "timeframe";
    },
    isDateRangeValid() {
      const { from, to } = this.dateRanges[this.activeTab];
      if (from && to) {
        return new Date(from) <= new Date(to);
      }
      return true;
    },
    searchAccount: {
      get() {
        return this.accountList.search;
      },
      set(searchInput) {
        if (this.accountList.search !== searchInput) {
          this.accountList.search = searchInput;
          this.accountList.loading = true;
          this.debounceAccountSearch();
        }
      },
    },
  },
  methods: {
    possibleDataPointsCount() {
      const containerWidth =
        document.getElementById("analytics-graph-content").offsetWidth - 92;
      return Math.abs(Math.floor(containerWidth / 2));
    },
    debounceAnalytics: debounce(function () {
      this.initializeAnalytics();
    }, 500),
    initializeAnalytics() {
      const analyticsRanges = this.dateRanges.analytics;
      const subscriptionsRanges = this.dateRanges.subscriptions;
      if (
        this.activeTab === "analytics" &&
        ((analyticsRanges.from && analyticsRanges.to) ||
          (!analyticsRanges.from && !analyticsRanges.to))
      ) {
        this.getCompanyTiles();
        this.getProductTiles();
        this.getCollectionListing();
        this.getPublishedTraceListing();
        this.getActiveCompaniesListing();
        this.getInactiveCompaniesListing();
        this.getMobileAppUsageListing();
        this.getMapData();
        this.retrievedGraphTypes = [];
        this.getGraphData(this.checkedGraphTypes);
      } else if (
        this.activeTab === "subscriptions" &&
        ((subscriptionsRanges.from && subscriptionsRanges.to) ||
          (!subscriptionsRanges.from && !subscriptionsRanges.to))
      ) {
        this.getSubscriptionTiles();
        this.getSubscriptionList();
      }
    },
    getOneYearAheadDate() {
      const today = new Date();
      const oneYearAhead = new Date(today.setFullYear(today.getFullYear() + 1));
      return oneYearAhead.toISOString().substr(0, 10);
    },
    setDefaultDateRanges() {
      const today = new Date();
      const toDate = new Date(today);
      toDate.setDate(today.getDate());
      const toDateFormatted = toDate.toISOString().split("T")[0];

      const fromDate = new Date(today);
      fromDate.setDate(fromDate.getDate() - 30);
      const fromDateFormatted = fromDate.toISOString().split("T")[0];

      this.dateRanges.analytics.from = fromDateFormatted;
      this.dateRanges.analytics.to = toDateFormatted;
      this.dateRanges.subscriptions.from = fromDateFormatted;
      this.dateRanges.subscriptions.to = toDateFormatted;

      this.dateRangeAnalytics[0].text = `Timeframe: ${fromDateFormatted} - ${toDateFormatted}`;
      this.dateRangeSubscriptions[0].text = `Timeframe: ${fromDateFormatted} - ${toDateFormatted}`;
    },
    onClickTab(tab) {
      this.activeTab = tab;
    },
    openDatePicker() {
      this.dateMenus[this.activeTab].range = true;
    },
    closeDatePicker() {
      this.dateMenus[this.activeTab].range = false;
    },
    selectTab(option = null) {
      this.selectedTab[this.activeTab] = option;
      const today = new Date();
      let fromDate;
      if (option === "Custom") {
        fromDate.setMonth(today.getMonth() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      } else if (option === "1 month") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      } else if (option === "2 weeks") {
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 13);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      } else if (option === "3 months") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 3);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      } else if (option === "6 months") {
        fromDate = new Date(today);
        fromDate.setMonth(today.getMonth() - 6);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      } else if (option === "1 year") {
        fromDate = new Date(today);
        fromDate.setFullYear(today.getFullYear() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.dateRanges[this.activeTab].from = this.formatDate(fromDate);
        this.dateRanges[this.activeTab].to = this.formatDate(today);
      }

      if (this.activeTab === "analytics") {
        this.dateRangeAnalytics[0].text = `Timeframe: ${this.dateRanges.analytics.from} - ${this.dateRanges.analytics.to}`;
      } else if (this.activeTab === "subscriptions") {
        this.dateRangeSubscriptions[0].text = `Timeframe: ${this.dateRanges.subscriptions.from} - ${this.dateRanges.subscriptions.to}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date.toISOString().split("T")[0];
    },
    handleDateRangeChange() {
      if (this.selectedDateRange === "default") {
        this.openDatePicker();
      }
    },
    openNewSubscriptionDialog() {
      this.newSubscriptionDialog = true;
    },
    closeNewSubscriptionDialog() {
      this.$refs.newSubscriptionData.resetValidation();
      this.newSubscriptionDialog = false;
      this.newSubscriptionData = {
        package: null,
        account: null,
        due: this.getOneYearAheadDate(),
        amount: null,
        verified: false,
      };
    },
    checkIsFiltersMatching(used, current) {
      const usedFilters = { ...used };
      const currentFilters = { ...current };
      if (
        (!currentFilters.from || !currentFilters.to) &&
        (!usedFilters.from || !usedFilters.to)
      ) {
        usedFilters.from = null;
        usedFilters.to = null;
        currentFilters.from = null;
        currentFilters.to = null;
      }

      return Object.keys(usedFilters).every(
        (key) => usedFilters[key] === currentFilters[key]
      );
    },
    async getCompanyTiles() {
      this.companyTilesLoading = true;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/companies?type=${this.accountTypes.analytics}${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyTilesLoading = false;
        this.companyTiles = {
          ...this.companyTiles,
          createdRegistries: {
            ...this.companyTiles.createdRegistries,
            ...response.data.created_registries,
          },
          registeredCompanies: {
            ...this.companyTiles.registeredCompanies,
            ...response.data.registered_companies,
          },
          verifiedCompanies: {
            ...this.companyTiles.verifiedCompanies,
            ...response.data.verified_companies,
          },
          unverifiedCompanies: {
            ...this.companyTiles.unverifiedCompanies,
            ...response.data.unverified_companies,
          },
          paidSubscriptions: {
            ...this.companyTiles.paidSubscriptions,
            ...response.data.paid_subscriptions,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getProductTiles() {
      this.productTilesLoading = true;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/products?type=${this.accountTypes.analytics}${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.productTilesLoading = false;
        this.productTiles = {
          ...this.productTiles,
          createdCollections: {
            ...this.productTiles.createdCollections,
            ...response.data.created_collections,
          },
          completedCollections: {
            ...this.productTiles.completedCollections,
            ...response.data.completed_collections,
          },
          createdStyles: {
            ...this.productTiles.createdStyles,
            ...response.data.created_inquiries,
          },
          completedStyles: {
            ...this.productTiles.completedStyles,
            ...response.data.completed_inquiries,
          },
          totalProducts: {
            ...this.productTiles.totalProducts,
            ...response.data.total_products,
          },
          dppCreated: {
            ...this.productTiles.dppCreated,
            ...response.data.created_traces,
          },
          dppPublished: {
            ...this.productTiles.dppPublished,
            ...response.data.published_traces,
          },
          updatesPublishedDpp: {
            ...this.productTiles.updatesPublishedDpp,
            ...response.data.changes_published_traces,
          },
          totalTraceableProducts: {
            ...this.productTiles.totalTraceableProducts,
            ...response.data.traceable_products,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    updateCheckedGraphTypes(types) {
      this.checkedGraphTypes = types;
      this.retrievedGraphTypes = this.retrievedGraphTypes.filter((type) =>
        types.includes(type)
      );
    },
    async getGraphData(graphTypes) {
      if (!this.graphClassesCount) {
        this.retrievedGraphTypes = [];
        this.graphClassesCount = this.possibleDataPointsCount();
      }
      this.updateCheckedGraphTypes(graphTypes);
      if (!this.dateRanges.analytics.from || !this.dateRanges.analytics.to) {
        return;
      }
      const typesNeedsUpdate = graphTypes.filter(
        (type) => !this.retrievedGraphTypes.includes(type)
      );
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
      };
      for (const type of typesNeedsUpdate) {
        this.graphLoading = true;
        try {
          const response = await restAdapter.get(
            `/api/analytics/graph?type=${this.accountTypes.analytics}${
              this.dateRanges.analytics.from && this.dateRanges.analytics.to
                ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
                : ""
            }&graph=${type}&classes=${this.graphClassesCount}`
          );
          const currentFilters = {
            type: this.accountTypes.analytics,
            start: this.dateRanges.analytics.from,
            end: this.dateRanges.analytics.to,
          };
          if (!this.checkIsFiltersMatching(filters, currentFilters)) {
            return;
          }
          this.graphData = {
            ...this.graphData,
            [type]: response.data.map((item) => ({
              date: item.date,
              value: item.count,
            })),
          };
          this.retrievedGraphTypes.push(type);
          this.graphLoading = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getSubscriptionTiles() {
      this.subscriptionTilesLoading = true;
      const filters = {
        type: this.accountTypes.subscriptions,
        start: this.dateRanges.subscriptions.from,
        end: this.dateRanges.subscriptions.to,
      };
      try {
        const response = await restAdapter.get(
          `/api/subscriptions/income?type=${this.accountTypes.subscriptions}${
            this.dateRanges.subscriptions.from &&
            this.dateRanges.subscriptions.to
              ? `&start=${this.dateRanges.subscriptions.from}&end=${this.dateRanges.subscriptions.to}`
              : ""
          }`
        );
        const currentFilters = {
          type: this.accountTypes.subscriptions,
          start: this.dateRanges.subscriptions.from,
          end: this.dateRanges.subscriptions.to,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.subscriptionTilesLoading = false;
        this.subscriptionTiles = {
          ...this.subscriptionTiles,
          freeSubscriptions: {
            ...this.subscriptionTiles.freeSubscriptions,
            ...{
              count: response.data.free?.count || 0,
              percentage: response.data.free?.percentage,
            },
          },
          startupSubscriptions: {
            ...this.subscriptionTiles.startupSubscriptions,
            ...{
              count: response.data.startup?.count || 0,
              percentage: response.data.startup?.percentage,
              amount: response.data.startup?.amount || 0,
            },
          },
          growthSubscriptions: {
            ...this.subscriptionTiles.growthSubscriptions,
            ...{
              count: response.data.growth?.count || 0,
              percentage: response.data.growth?.percentage,
              amount: response.data.growth?.amount || 0,
            },
          },
          enterpriseSubscriptions: {
            ...this.subscriptionTiles.enterpriseSubscriptions,
            ...{
              count: response.data.enterprise?.count || 0,
              percentage: response.data.enterprise?.percentage,
              amount: response.data.enterprise?.amount || 0,
            },
          },
          totalSubscriptions: {
            ...this.subscriptionTiles.totalSubscriptions,
            ...{
              count: response.data.total?.count || 0,
              percentage: response.data.total?.percentage,
              amount: response.data.total?.amount || 0,
            },
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getSubscriptionList(
      filters = { search: "", packageId: null, order: "asc" },
      page = 1
    ) {
      this.subscriptionFilters = { ...filters, page: page };
      const usedFilters = {
        search: this.subscriptionFilters.search,
        packageId: this.subscriptionFilters.packageId,
        order: this.subscriptionFilters.order,
        page: this.subscriptionFilters.page,
        type: this.accountTypes.subscriptions,
        start: this.dateRanges.subscriptions.from,
        end: this.dateRanges.subscriptions.to,
      };
      try {
        this.subscriptionListing.loading = true;
        const response = await restAdapter.get(
          `/api/subscriptions?type=${this.accountTypes.subscriptions}${
            this.dateRanges.subscriptions.from &&
            this.dateRanges.subscriptions.to
              ? `&start=${this.dateRanges.subscriptions.from}&end=${this.dateRanges.subscriptions.to}`
              : ""
          }&page=${page}${
            filters.package && Number.isInteger(filters.package)
              ? `&package=${filters.package}`
              : ""
          }${filters.search ? `&query=${filters.search}` : ""}&due=${
            filters.order
          }`
        );
        const currentFilters = {
          search: this.subscriptionFilters.search,
          packageId: this.subscriptionFilters.packageId,
          order: this.subscriptionFilters.order,
          page: this.subscriptionFilters.page,
          type: this.accountTypes.subscriptions,
          start: this.dateRanges.subscriptions.from,
          end: this.dateRanges.subscriptions.to,
        };
        if (!this.checkIsFiltersMatching(usedFilters, currentFilters)) {
          return;
        }
        this.subscriptionListing = {
          ...this.subscriptionListing,
          loading: false,
          data: response.data.data.map((item) => ({
            id: item.id,
            package: item.package.name,
            name: item.account.organization_registry.name,
            due: item.due_on,
            amount: `£${Number(
              Number(item.amount).toFixed(2)
            ).toLocaleString()}`,
            avatar: item.account.logo
              ? `${this.baseUrl}/api/account/logo_show/${item.account.uuid}/${item.account.logo}`
              : null,
          })),
          total: response.data.total,
          page: page,
          filters: filters,
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getCollectionListing(page = 1) {
      this.companyListing.completedCollectionsListing.loading = true;
      this.companyListing.completedCollectionsListing.page = page;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
        page: this.companyListing.completedCollectionsListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/collections?type=${
            this.accountTypes.analytics
          }${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }&page=${page}`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
          page: this.companyListing.completedCollectionsListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyListing = {
          ...this.companyListing,
          completedCollectionsListing: {
            ...this.companyListing.completedCollectionsListing,
            page: page,
            total: response.data.total,
            data: response.data.data.map((item) => ({
              ...item,
              id: item.id,
              name: item.name,
              position: item.current_position,
              navigation: `${window.location.origin}/#/app/company/${item.uuid}`,
              avatar:
                item.account && item.account.uuid && item.account.logo
                  ? `${this.baseUrl}/api/account/logo_show/${item.account.uuid}/${item.account.logo}`
                  : null,
              up: item.previous_position
                ? item.previous_position > item.current_position
                : false,
              down: item.previous_position
                ? item.previous_position < item.current_position
                : false,
              same: item.previous_position
                ? item.previous_position === item.current_position
                : false,
              details: [
                `${
                  item.completed_owned_collections_count +
                  item.completed_created_collections_count
                } Completed collections`,
                `${
                  item.not_yet_completed_owned_collections_count +
                  item.not_yet_completed_created_collections_count
                } On going collections`,
              ],
            })),
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getPackageList() {
      try {
        const response = await restAdapter.get("/api/subscriptions/packages");
        this.packages = [{ id: null, name: "All" }, ...response.data.packages];
      } catch (error) {
        console.error(error);
      }
    },
    debounceAccountSearch: debounce(function () {
      this.fetchAccountList();
    }, 600),
    fetchAccountList($state = null) {
      this.accountList.loading = true;
      let url = "/api/accounts";
      const page = $state ? this.accountList.page : 1;
      url +=
        "?page=" +
        page +
        "&query=" +
        (this.accountList.search ? this.accountList.search : "");

      if (page === 1 && this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset();
      }

      restAdapter.get(url).then((response) => {
        this.accountList.loading = false;
        if (response.data.data.length) {
          this.accountList.data = $state
            ? this.accountList.data.concat(response.data.data)
            : response.data.data;
          this.accountList.page = page + 1;
          $state?.loaded();
        } else {
          $state?.complete();
        }
      });
    },
    async deleteSubscription(
      filters = { search: "", package: null, order: "asc" },
      page = 1,
      id
    ) {
      const confirm = window.confirm(
        "Are you sure you want to delete this subscription?"
      );
      if (!confirm) {
        return;
      }
      try {
        await restAdapter.delete(`/api/subscriptions/${id}`);
        this.getSubscriptionList(filters, page);
        this.getSubscriptionTiles();
      } catch (error) {
        console.error(error);
      }
    },
    async createSubscription() {
      if (!this.$refs.newSubscriptionData.validate()) {
        return;
      }
      try {
        /* eslint-disable @typescript-eslint/camelcase */
        await restAdapter.post("/api/subscriptions", {
          account_id: this.newSubscriptionData.account,
          subscription_package_id: this.newSubscriptionData.package,
          due_on: this.newSubscriptionData.due,
          amount: this.newSubscriptionData.amount || 0,
          verified: this.newSubscriptionData.verified,
        });
        /* eslint-enable @typescript-eslint/camelcase */
        const accountIndex = this.accountList.data.findIndex(
          (item) => item.id === this.newSubscriptionData.account
        );
        if (accountIndex !== -1) {
          this.accountList.data[accountIndex].verified =
            this.newSubscriptionData.verified;
        }
        this.closeNewSubscriptionDialog();
        this.getSubscriptionList(
          this.subscriptionListing.filters,
          this.subscriptionListing.page
        );
        this.getSubscriptionTiles();
      } catch (error) {
        console.error(error);
      }
    },
    async getPublishedTraceListing(page = 1) {
      this.companyListing.publishedTraceListing.loading = true;
      this.companyListing.publishedTraceListing.page = page;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
        page: this.companyListing.publishedTraceListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/trace?type=${this.accountTypes.analytics}${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }&page=${page}`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
          page: this.companyListing.publishedTraceListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyListing = {
          ...this.companyListing,
          publishedTraceListing: {
            ...this.companyListing.publishedTraceListing,
            page: page,
            total: response.data.total,
            data: response.data.data.map((item) => ({
              ...item,
              id: item.id,
              name: item.name,
              position: item.current_position,
              navigation: `${window.location.origin}/#/app/company/${item.uuid}`,
              avatar:
                item.account && item.account.uuid && item.account.logo
                  ? `${this.baseUrl}/api/account/logo_show/${item.account.uuid}/${item.account.logo}`
                  : null,
              up: item.previous_position
                ? item.previous_position > item.current_position
                : false,
              down: item.previous_position
                ? item.previous_position < item.current_position
                : false,
              same: item.previous_position
                ? item.previous_position === item.current_position
                : false,
              details: [
                `${
                  item.trace_published_owned_inquiries_count +
                  item.trace_published_created_inquiries_count
                } Published DPPs`,
                `${
                  item.not_yet_trace_published_created_inquiries_count +
                  item.not_yet_trace_published_owned_inquiries_count
                } Unpublished DPPs`,
              ],
            })),
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getActiveCompaniesListing(page = 1) {
      this.companyListing.activeCompaniesListing.loading = true;
      this.companyListing.activeCompaniesListing.page = page;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
        page: this.companyListing.activeCompaniesListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/interactions?type=${
            this.accountTypes.analytics
          }${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }&page=${page}`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
          page: this.companyListing.activeCompaniesListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyListing = {
          ...this.companyListing,
          activeCompaniesListing: {
            ...this.companyListing.activeCompaniesListing,
            page: page,
            total: response.data.total,
            data: response.data.data.map((item) => ({
              ...item,
              id: item.id,
              name: item.organization_registry.name,
              position: item.current_position,
              navigation: `${window.location.origin}/#/app/company/${item.organization_registry.uuid}`,
              avatar:
                item.uuid && item.logo
                  ? `${this.baseUrl}/api/account/logo_show/${item.uuid}/${item.logo}`
                  : null,
              up: item.previous_position
                ? item.previous_position > item.current_position
                : false,
              down: item.previous_position
                ? item.previous_position < item.current_position
                : false,
              same: item.previous_position
                ? item.previous_position === item.current_position
                : false,
              details: [
                `${item.messages_count} Interactions`,
                `${item.users_count} Users`,
              ],
            })),
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getInactiveCompaniesListing(page = 1) {
      this.companyListing.inactiveCompaniesListing.loading = true;
      this.companyListing.inactiveCompaniesListing.page = page;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
        page: this.companyListing.inactiveCompaniesListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/inactive?type=${this.accountTypes.analytics}${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }&page=${page}`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
          page: this.companyListing.inactiveCompaniesListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyListing = {
          ...this.companyListing,
          inactiveCompaniesListing: {
            ...this.companyListing.inactiveCompaniesListing,
            page: page,
            total: response.data.total,
            data: response.data.data.map((item) => ({
              ...item,
              id: item.id,
              name: item.organization_registry.name,
              position: item.current_position,
              navigation: `${window.location.origin}/#/app/company/${item.organization_registry.uuid}`,
              avatar:
                item.uuid && item.logo
                  ? `${this.baseUrl}/api/account/logo_show/${item.uuid}/${item.logo}`
                  : null,
              details: [
                `${moment(item.last_active.last_active_at).fromNow()}`,
                `${item.users_count} Users`,
              ],
            })),
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getMobileAppUsageListing(page = 1) {
      this.companyListing.mobileAppUsageListing.loading = true;
      this.companyListing.mobileAppUsageListing.page = page;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
        page: this.companyListing.mobileAppUsageListing.page,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/mobile?type=${this.accountTypes.analytics}${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }&page=${page}`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
          page: this.companyListing.mobileAppUsageListing.page,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.companyListing = {
          ...this.companyListing,
          mobileAppUsageListing: {
            ...this.companyListing.mobileAppUsageListing,
            page: page,
            total: response.data.total,
            data: response.data.data.map((item) => ({
              ...item,
              id: item.id,
              name: item.organization_registry.name,
              position: item.current_position,
              navigation: `${window.location.origin}/#/app/company/${item.organization_registry.uuid}`,
              avatar:
                item.uuid && item.logo
                  ? `${this.baseUrl}/api/account/logo_show/${item.uuid}/${item.logo}`
                  : null,
              details: [
                `${item.mobile_push_tokens_count} Devices`,
                `${item.users_count} Users`,
              ],
            })),
            loading: false,
          },
        };
      } catch (error) {
        console.error(error);
      }
    },
    async getMapData() {
      this.mapLoading = true;
      const filters = {
        type: this.accountTypes.analytics,
        start: this.dateRanges.analytics.from,
        end: this.dateRanges.analytics.to,
      };
      try {
        const response = await restAdapter.get(
          `/api/analytics/listing/countries?type=${
            this.accountTypes.analytics
          }${
            this.dateRanges.analytics.from && this.dateRanges.analytics.to
              ? `&start=${this.dateRanges.analytics.from}&end=${this.dateRanges.analytics.to}`
              : ""
          }`
        );
        const currentFilters = {
          type: this.accountTypes.analytics,
          start: this.dateRanges.analytics.from,
          end: this.dateRanges.analytics.to,
        };
        if (!this.checkIsFiltersMatching(filters, currentFilters)) {
          return;
        }
        this.countriesMapPagination = {
          ...this.countriesMapPagination,
          page: 1,
          total: response.data.countries.length,
          data: response.data.countries,
        };
        this.mapLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    changeMapPage(page) {
      this.countriesMapPagination.page = page;
    },
    loadListingPage(method, page) {
      this[method](page);
    },
  },
};
</script>
<style lang="scss" scoped>
.tiles-container {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}
.centered-card {
  margin: 8px;
  padding: 8px;
}

.date-input {
  margin-top: 8px;
}

.tabs-column {
  padding: 0;
}

.tab-item {
  font-size: 0.875rem;
  padding: 4px 8px;
}

.divider {
  margin: 8px 0;
  height: calc(100% - 16px);
}
.date-input {
  margin-top: 5px;
}
.listings-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.tile-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.main_container {
  padding: 20px;
}

.trace-tab-buttons-container {
  align-self: flex-start;
  width: fit-content;
  height: fit-content;
  margin-left: 80px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #eef1fa;
  border-radius: 20px;
  border: 1px solid #473068;
  margin: 20px 0px;
}

.trace-tab-button {
  height: 30px;
  width: 110px;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #473068;
  cursor: pointer;
}

.trace-tab-button-active {
  background-color: white;
  border: 1px solid #473068;
  border-radius: 15px;
  cursor: initial;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.filter-row {
  display: flex;
  margin-left: 80px !important;
  align-items: center;
  gap: 16px;
}

.filter-select {
  background-color: #eff0f6;
  border-radius: 40px;
  max-width: 330px;
  width: fit-content;
  color: #473068;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  border: none !important;
  outline: none !important;
}

.filter-type {
  background-color: #eff0f6;
  border-radius: 40px;
  max-width: 220px;
  width: fit-content;
  color: #473068;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  border: none !important;
  outline: none !important;
}

.v-card-title,
.v-text-field .v-label,
.v-text-field .v-input__control .v-input__slot input {
  color: #473068 !important;
}

.v-card {
  padding: 20px;
}

.date-input {
  border: none !important;
}

.v-btn {
  color: #473068 !important;
}

.v-alert {
  color: #473068 !important;
}

.v-card-title {
  font-weight: bold;
  font-size: 16px;
}

.centered-card {
  margin: auto;
  color: #473068 !important;
}

.new-subscription-container {
  padding: 20px;
  background: #eef1fa;
}

.new-subscription-title {
  color: #5b3d87;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.new-subscription-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .v-input--selection-controls {
    padding: 0px;
  }
}

.new-subscription-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
<style>
.v-menu__content {
  z-index: 250 !important;
}
</style>
